@charset "UTF-8";
.l-header {
  position: fixed;
  width: 100%;
  height: 99px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
  padding: 0 15px 0 24px;
  z-index: 10;
}

@media (max-width: 425px) {
  .l-header {
    height: 70px;
    padding: 0 0 0 13px;
  }
}

.l-header--sp_only_fixed_link {
  display: none;
}

@media (max-width: 425px) {
  .l-header--sp_only_fixed_link {
    position: fixed;
    left: 0;
    bottom: 40px;
    border: 2px solid #fff;
    border-left: none;
    border-radius: 0 16px 16px 0;
    background-color: #333333;
    color: #fff;
    width: 82px;
    height: 78px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.25;
    text-align: center;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2215.999%22%20viewBox%3D%220%200%2016%2015.999%22%3E%20%3Cpath%20id%3D%22Icon_awesome-pen%22%20data-name%3D%22Icon%20awesome-pen%22%20d%3D%22M9.085%2C2.914l4%2C4L4.4%2C15.6.832%2C16A.75.75%2C0%2C0%2C1%2C0%2C15.167L.4%2C11.6%2C9.085%2C2.914Zm6.475-.6L13.681.44a1.5%2C1.5%2C0%2C0%2C0-2.122%2C0L9.792%2C2.207l4%2C4L15.56%2C4.44a1.5%2C1.5%2C0%2C0%2C0%2C0-2.122Z%22%20transform%3D%22translate%280.001%20-0.001%29%22%20fill%3D%22%23f4b800%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: center bottom 10px;
    padding-top: 9px;
  }
  .l-header--sp_only_fixed_link.js-show {
    display: block;
  }
}

.l-header--menu {
  display: flex;
  align-items: center;
}

@media (max-width: 425px) {
  .l-header--menu {
    float: none;
    position: static;
  }
}

.l-header--sns {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 14px;
  color: #1777F2;
  display: flex;
  align-items: center;
}

@media (max-width: 425px) {
  .l-header--sns {
    display: none;
  }
}

.l-header--sns:after {
  font-family: 'shigotojuku';
  content: '\f09a';
}

.l-header--sns svg {
  width: 20px;
  height: 20px;
}

.l-header--sns_text {
  font-weight: 700;
  margin-right: 3px;
}

.l-header--hamburger {
  display: none;
}

@media (max-width: 425px) {
  .l-header--hamburger {
    width: 70px;
    height: 70px;
    display: block;
    margin-right: 0;
    border-left: 1px solid #ccc;
    position: relative;
  }
}

.l-header--hamburger-open .l-header--hamburger_icon {
  transform: rotate(45deg);
}

.l-header--hamburger-open .l-header--hamburger_icon:before {
  top: 0;
}

.l-header--hamburger-open .l-header--hamburger_icon:after {
  bottom: 0;
  transform: rotate(-90deg);
}

@media (max-width: 425px) {
  .l-header--hamburger_txt {
    display: inline-block;
    transition: .2s;
    position: absolute;
    left: calc(100% - 4em);
    bottom: 5px;
    font-size: 14px;
    text-align: center;
  }
}

@media (max-width: 425px) {
  .l-header--hamburger_icon {
    width: 36px;
    height: 5px;
    border-radius: 3px;
    background: #ff8a16;
    position: absolute;
    transition: .2s;
    top: 27px;
    left: 15px;
    display: block;
  }
}

@media (max-width: 425px) {
  .l-header--hamburger_icon:before {
    content: '';
    display: block;
    background: #ff8a16;
    border-radius: 3px;
    position: absolute;
    width: 36px;
    height: 5px;
    top: -12px;
  }
}

@media (max-width: 425px) {
  .l-header--hamburger_icon:after {
    content: '';
    display: block;
    background: #ff8a16;
    border-radius: 3px;
    position: absolute;
    width: 36px;
    height: 5px;
    bottom: -12px;
  }
}

@media (max-width: 425px) {
  .l-header .m-btn {
    display: none;
  }
}

.l-header .m-btn a {
  font-size: 16px;
  width: 240px;
  padding: 10px 20px;
  border-radius: 25px;
}

.l-header .m-btn a:after {
  margin-top: -12px;
  right: 10px;
}

.l-header_logo {
  display: flex;
  align-items: center;
}

.l-header_logo--tokyo {
  width: 140px;
  height: 40px;
  display: block;
  background: url("../images/tokyo.svg") no-repeat center center;
  background-size: cover;
  transition: .2s;
}

@media screen and (max-width: 1105px) {
  .l-header_logo--tokyo {
    width: 100px;
    height: 29px;
    margin-top: 35px;
  }
}

@media (max-width: 425px) {
  .l-header_logo--tokyo {
    display: none;
  }
}

.l-header_logo--4510jk {
  width: 220px;
  height: 70px;
  display: block;
  background: url("../images/logo.svg") no-repeat center center;
  margin-left: 20px;
  background-size: cover;
  transition: .2s;
}

@media screen and (max-width: 1105px) {
  .l-header_logo--4510jk {
    width: 158px;
    height: 50px;
  }
}

@media (max-width: 425px) {
  .l-header_logo--4510jk {
    width: 154px;
    margin-left: 0;
  }
}

.l-header_sns {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

@media (max-width: 425px) {
  .l-header_sns {
    margin-right: 20px;
  }
}

.l-header_sns--link {
  width: 24px;
  height: 24px;
  display: block;
  transition: .2s;
}

.l-header_sns--link:nth-child(n+2) {
  margin-left: 18px;
}

.l-header_sns--link:before {
  content: '';
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  transition: .2s;
}

.l-header_sns--link:hover:before {
  opacity: 0.8;
}

.l-header_sns--link-facebook:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224.147%22%20height%3D%2224%22%20viewBox%3D%220%200%2024.147%2024%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_9479%22%20data-name%3D%22%E3%83%91%E3%82%B9%209479%22%20d%3D%22M848.837%2C582.019a12.074%2C12.074%2C0%2C0%2C0-1.887%2C24v-8.437h-3.065v-3.49h3.065v-2.66c0-3.026%2C1.8-4.7%2C4.561-4.7a18.536%2C18.536%2C0%2C0%2C1%2C2.7.236v2.971h-1.522a1.745%2C1.745%2C0%2C0%2C0-1.968%2C1.885v2.265h3.349l-.536%2C3.49h-2.813v8.437a12.074%2C12.074%2C0%2C0%2C0-1.886-24Z%22%20transform%3D%22translate(-836.763%20-582.019)%22%20fill%3D%22%230076fb%22%2F%3E%3C%2Fsvg%3E");
}

.l-header_sns--link-twitter:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_8906%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%208906%22%20transform%3D%22translate%28-635.625%20-12755%29%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3695%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203695%22%20width%3D%2224%22%20height%3D%2224%22%20transform%3D%22translate%28635.625%2012755%29%22%2F%3E%20%3Cpath%20id%3D%22logo%22%20d%3D%22M7.862%2C5.716%2C12.779%2C0H11.614L7.344%2C4.963%2C3.934%2C0H0L5.157%2C7.506%2C0%2C13.5H1.165L5.675%2C8.258l3.6%2C5.242H13.21L7.861%2C5.716Zm-1.6%2C1.855-.523-.747L1.585.877h1.79l3.355%2C4.8.523.747%2C4.361%2C6.239H9.824L6.265%2C7.572Z%22%20transform%3D%22translate%28640.875%2012760.25%29%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
}

.l-header_nav {
  width: 100%;
}

@media (max-width: 425px) {
  .l-header_nav {
    display: none;
    top: 70px;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    background: #f2f2f2;
  }
}

.l-header_nav_list {
  width: 490px;
  margin: 0 auto;
  position: relative;
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1105px) {
  .l-header_nav_list {
    width: 460px;
  }
}

@media (max-width: 425px) {
  .l-header_nav_list {
    width: 100%;
    display: block;
    padding: 20px 30px;
  }
}

.l-header_nav_list--item {
  width: calc(100% / 3);
}

.l-header_nav_list--item-sp_only {
  display: none;
}

@media (max-width: 425px) {
  .l-header_nav_list--item {
    width: 100%;
    margin-top: 10px;
  }
  .l-header_nav_list--item-sp_only {
    display: block;
  }
}

@media (max-width: 425px) {
  .l-header_nav_list--item:first-child {
    margin-top: 0;
  }
}

.l-header_nav_list--link {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  display: inline-block;
  padding: 5px 0;
  transition: .15s;
}

@media (max-width: 425px) {
  .l-header_nav_list--link {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #f4b800;
    text-align: center;
    border-radius: 20px;
  }
  .l-header_nav_list--link-sp_gray {
    color: #fff;
    background-color: #5E5E5E;
  }
  .l-header_nav_list--link-sp_orange {
    background-color: #ff8a16;
    color: #fff;
  }
}

.l-header_nav_list--link:before {
  content: '●';
  color: #f4b800;
}

@media (max-width: 425px) {
  .l-header_nav_list--link:before {
    content: none;
  }
}

.l-header_nav_list--link:hover {
  color: #f4b800;
}

@media all and (-ms-high-contrast: none) {
  .l-header_nav_list--link {
    font-weight: 400;
  }
}

@media screen and (max-width: 320px) {
  .l-header .m-btn {
    margin-right: 5px;
  }
  .l-header .m-btn a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .l-header .m-btn a:after {
    content: none;
  }
  .l-header_logo--logo {
    width: 90px;
    height: 29px;
  }
}

.l-footer--pagetop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 46px;
  height: 46px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  transition: .2s;
  z-index: 10;
}

.l-footer--pagetop:before {
  font-family: 'shigotojuku-solid';
  content: '\f106';
  color: #fff;
  font-size: 26px;
  display: block;
  text-align: center;
  top: 7px;
  position: relative;
}

.l-footer--pagetop:hover {
  background: rgba(0, 0, 0, 0.6);
}

.l-footer--pagetop-show {
  opacity: 1;
}

.l-footer--ttl {
  font-size: 40px;
  line-height: 1.8;
  color: #fff;
  background: #ff8a16;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.l-footer--map {
  width: 100%;
  height: 400px;
  display: block;
}

.l-facility {
  padding-top: 50px;
  padding-bottom: 50px;
}

.l-facility_about {
  display: flex;
  justify-content: space-between;
}

.l-facility_about--ttl {
  font-size: 24px;
  line-height: 1.7;
  margin-bottom: 5px;
}

.l-facility_about--ttl strong {
  color: #ff8a16;
}

.l-facility_about--sc {
  position: relative;
  top: 8px;
}

.l-facility_about--tel {
  font-size: 40px;
  font-weight: 700;
  color: #ff8a16;
  line-height: 1.2;
  margin-bottom: 5px;
  display: inline-block;
}

.l-facility_about--tel:before {
  content: '\f095';
  font-family: 'shigotojuku-solid';
  color: #333;
}

.l-facility_about--txt {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.l-facility_about--heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

.l-facility_about--heading:before {
  content: '◯';
  color: #ff8a16;
}

.l-facility_about--access {
  padding-top: 5px;
}

.l-footer_nav-main {
  padding-top: 25px;
  padding-bottom: 25px;
  background: #ff8a16;
}

.l-footer_nav-main .l-footer_nav_list--item:last-child .l-footer_nav_list--link {
  border-right: 1px solid #fff;
}

.l-footer_nav-main .l-footer_nav_list--link {
  font-size: 14px;
  color: #fff;
  border-left: 1px solid #fff;
  text-align: center;
}

.l-footer_nav-main .l-footer_nav_list--link:after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  left: 10%;
  bottom: -5px;
  background: #fff;
  transition: width .25s;
}

.l-footer_nav-main .l-footer_nav_list--link:hover:after {
  width: 80%;
}

.l-footer_nav-sub .l-footer_nav_list--item-pasona {
  flex-grow: 1;
  text-align: right;
}

.l-footer_nav-sub .l-footer_nav_list--item-pasona img {
  width: 150px;
}

.l-footer_nav-sub .l-footer_nav_list--link {
  font-size: 12px;
  text-align: center;
}

.l-footer_nav-sub .l-footer_nav_list--link:hover {
  text-decoration: underline;
}

.l-footer_nav_list {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  align-items: center;
}

.l-footer_nav_list--link {
  width: calc(1000px / 6);
  display: block;
  line-height: 1.5;
  position: relative;
}

.l-footer_copy {
  font-size: 14px;
  line-height: 1.7;
  text-align: center;
  background: #cccccc;
  padding-top: 35px;
  padding-bottom: 45px;
}

.l-footer_modal.js-display_none {
  display: none;
}

.l-footer_modal--open {
  position: fixed;
  bottom: 24px;
  left: 0;
  z-index: 10;
  background: #ff8a16;
  border-radius: 0 31px 31px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 196px;
  height: 62px;
  padding: 0 16px 0 45px;
  display: flex;
  align-items: center;
}

.l-footer_modal--button_text {
  font-size: 12px;
  font-weight: 700;
  position: relative;
}

.l-footer_modal--button_text:before {
  content: '';
  display: inline-block;
  background-image: url("/common/images/l-footer_modal--strong.svg");
  background-size: contain;
  width: 24px;
  height: 24px;
  position: absolute;
  left: -32px;
  top: calc(50% - 12px);
}

.l-footer_modal--strong {
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  display: block;
}

.l-footer_modal--area {
  display: none;
  transition: .1s;
  position: relative;
}

.l-footer_modal--area.js-modal {
  display: block;
}

.l-footer_modal--bg {
  background: rgba(112, 112, 112, 0.8);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.l-footer_modal--content {
  background: #fff;
  border: 1px solid #707070;
  padding: 40px;
  width: 800px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.l-footer_modal--heading {
  font-size: 24px;
  text-align: center;
  margin: 0 0 24px;
}

.l-footer_modal--text {
  font-size: 16px;
}

.l-footer_modal--text-strong {
  font-weight: 700;
}

.l-footer_modal--consult {
  font-size: 18px;
  color: #ff8a16;
  text-align: center;
  margin: 40px 0 8px;
}

.l-footer_modal--tel {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  display: block;
}

.l-footer_modal--close {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: #333;
  display: block;
  position: absolute;
  bottom: -50px;
  left: calc(50% - 16px);
}

.l-footer_modal--close:before, .l-footer_modal--close:after {
  content: '';
  width: 16px;
  height: 4px;
  background: #fff;
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 8px);
}

.l-footer_modal--close:before {
  transform: rotate(45deg);
}

.l-footer_modal--close:after {
  transform: rotate(135deg);
}

.l-footer_modal--hours {
  text-align: center;
  margin-top: 1em;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .l-footer--ttl {
    font-size: 24px;
    line-height: 1.5;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .l-footer--map {
    height: 53vw;
  }
  .l-footer_nav_list {
    width: 100%;
    display: block;
  }
  .l-facility {
    padding-top: 15px;
    padding-bottom: 0;
  }
  .l-facility-withfb {
    padding-bottom: 90px;
  }
  .l-facility .inner {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
  .l-facility_about {
    display: block;
  }
  .l-facility_about--info {
    padding-left: 15px;
    padding-right: 15px;
  }
  .l-facility_about--ttl {
    font-size: 22px;
    text-align: center;
  }
  .l-facility_about--sc {
    display: block;
    top: initial;
    margin: 0 auto;
    width: 150px;
  }
  .l-facility_about--tel {
    font-size: 30px;
    text-align: center;
    display: block;
  }
  .l-facility_about--heading {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }
  .l-facility_about--fb {
    font-size: 14px;
    position: absolute;
    left: 30px;
    bottom: -55px;
    padding: 10px 15px;
  }
  .l-facility_about--access {
    padding: 15px;
    background: #f2f2f2;
  }
  .l-footer_nav-main .l-footer_nav_list--item:last-child .l-footer_nav_list--link {
    border-right: 0;
  }
  .l-footer_nav-main .l-footer_nav_list--link {
    text-align: left;
    border: 0;
  }
  .l-footer_nav-sub .l-footer_nav_list {
    padding-top: 15px;
  }
  .l-footer_nav-sub .l-footer_nav_list--item-pasona {
    flex-grow: 0;
    text-align: center;
    margin-left: -30px;
  }
  .l-footer_nav-sub .l-footer_nav_list--link {
    text-align: left;
  }
  .l-footer_nav_list {
    padding-left: 30px;
  }
  .l-footer_nav_list--link {
    text-align: left;
    border: 0;
    line-height: 1.8;
  }
  .l-footer_copy {
    font-size: 12px;
    line-height: 1.5;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .l-footer_modal--content {
    padding: 16px 10px 20px;
    width: 300px;
  }
  .l-footer_modal--heading {
    font-size: 18px;
    margin: 0 0 16px;
  }
  .l-footer_modal--text {
    font-size: 14px;
  }
  .l-footer_modal--consult {
    font-size: 16px;
    margin: 32px 0 8px;
  }
  .l-footer_modal--tel {
    font-size: 24px;
  }
  .l-footer_modal--hours {
    font-size: 14px;
  }
}
