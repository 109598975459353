@charset "UTF-8";

@import "../mixin";

.l-footer {
  &--pagetop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 46px;
    height: 46px;
    opacity: 0;
    background: rgba(0,0,0,0.8);
    border-radius: 50%;
    transition: .2s;
    z-index: 10;
    &:before {
      font-family: 'shigotojuku-solid';
      content: '\f106';
      color: #fff;
      font-size: 26px;
      display: block;
      text-align: center;
      top: 7px;
      position: relative;
    }
    &:hover {
      background: rgba(0,0,0,0.6);
    }
    &-show {
      opacity: 1;
    }
  }
  &--ttl {
    font-size: 40px;
    line-height: 1.8;
    color: #fff;
    background: $color-main;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &--map {
    width: 100%;
    height: 400px;
    display: block;
  }
}

.l-facility {
  padding-top: 50px;
  padding-bottom: 50px;
}
.l-facility_about {
  display: flex;
  justify-content: space-between;
  &--info {
  }
  &--ttl {
    font-size: 24px;
    line-height: 1.7;
    margin-bottom: 5px;
    strong {
      color: $color-main;
    }
  }
  &--sc {
    position: relative;
    top: 8px;
  }
  &--tel {
    font-size: 40px;
    font-weight: $weight-bold;
    color: $color-main;
    line-height: 1.2;
    margin-bottom: 5px;
    display: inline-block;
    &:before {
      content: '\f095';
      font-family: 'shigotojuku-solid';
      color: #333;
    }
  }
  &--txt {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  &--heading {
    font-size: 20px;
    font-weight: $weight-bold;
    line-height: 1.5;
    &:before {
      content: '◯';
      color: $color-main;
    }
  }
  &--access {
    padding-top: 5px;
  }
}

.l-footer_nav {
  &-main {
    padding-top: 25px;
    padding-bottom: 25px;
    background: $color-main;
    .l-footer_nav_list {
      &--item {
        &:last-child {
          .l-footer_nav_list--link {
            border-right: 1px solid #fff;
          }
        }
      }
      &--link {
        font-size: 14px;
        color: #fff;
        border-left: 1px solid #fff;
        text-align: center;
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          left: 10%;
          bottom: -5px;
          background: #fff;
          transition: width .25s;
        }
        &:hover {
          &:after {
            width: 80%;
          }
        }
      }
    }
  }
  &-sub {
    .l-footer_nav_list {
      &--item {
        &-pasona {
          flex-grow: 1;
          text-align: right;
          img {
            width: 150px;
          }
        }
      }
      &--link {
        font-size: 12px;
        text-align: center;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.l-footer_nav_list {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  align-items: center;
  &--link {
    width: calc(1000px / 6);
    display: block;
    line-height: 1.5;
    position: relative;
  }
}

.l-footer_copy {
  font-size: 14px;
  line-height: 1.7;
  text-align: center;
  background: #cccccc;
  padding-top: 35px;
  padding-bottom: 45px;
}

//お気軽相談ダイヤル
.l-footer_modal {
  &.js-display_none {
    display: none;
  }
  &--open {
    position: fixed;
    bottom: 24px;
    left: 0;
    z-index: 10;
    background: $color-main;
    border-radius: 0 31px 31px 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    width: 196px;
    height: 62px;
    padding: 0 16px 0 45px;
    display: flex;
    align-items: center;
  }
  &--button_text {
    font-size: 12px;
    font-weight: $weight-bold;
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      background-image: url("/common/images/l-footer_modal--strong.svg");
      background-size: contain;
      width: 24px;
      height: 24px;
      position: absolute;
      left: -32px;
      top: calc(50% - 12px);
    }
  }
  &--strong {
    font-size: 15px;
    color: #fff;
    font-weight: $weight-bold;
    display: block;
  }
  &--area {
    display: none;
    transition: .1s;
    position: relative;
    &.js-modal {
      display: block;
    }
  }
  &--bg {
    background: rgba(112, 112, 112, .8);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  &--content {
    background: #fff;
    border: 1px solid #707070;
    padding: 40px;
    width: 800px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  &--heading {
    font-size: 24px;
    text-align: center;
    margin: 0 0 24px;
  }
  &--text {
    font-size: 16px;
    &-strong {
      font-weight: $weight-bold;
    }
  }
  &--consult {
    font-size: 18px;
    color: $color-main;
    text-align: center;
    margin: 40px 0 8px;
  }
  &--tel {
    font-size: 32px;
    font-weight: $weight-bold;
    text-align: center;
    display: block;
  }
  &--close {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: #333;
    display: block;
    position: absolute;
    bottom: -50px;
    left: calc(50% - 16px);
    &:before,&:after {
      content: '';
      width: 16px;
      height: 4px;
      background: #fff;
      position: absolute;
      top: calc(50% - 2px);
      left: calc(50% - 8px);
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(135deg);
    }
  }
  &--hours {
    text-align: center;
    margin-top: 1em;
    font-size: 14px;
  }
}

@media screen and (max-width:600px) {
  .l-footer {
    &--ttl {
      font-size: 24px;
      line-height: 1.5;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    &--map {
      height: 53vw;
    }
  }
  .l-footer_nav_list {
    width: 100%;
    display: block;
  }

  .l-facility {
    padding-top: 15px;
    padding-bottom: 0;
    &-withfb {
      padding-bottom: 90px;
    }
    .inner {
      padding-left: 0;
      padding-right: 0;
      position: relative;
    }
  }
  .l-facility_about {
    display: block;
    &--info {
      padding-left: 15px;
      padding-right: 15px;
    }
    &--ttl {
      font-size: 22px;
      text-align: center;
    }
    &--sc {
      display: block;
      top: initial;
      margin: 0 auto;
      width: 150px;
    }
    &--tel {
      font-size: 30px;
      text-align: center;
      display: block;
    }
    &--heading {
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px;
    }
    &--txt {}
    &--fb {
      font-size: 14px;
      position: absolute;
      left: 30px;
      bottom: -55px;
      padding: 10px 15px;
    }
    &--access {
      padding: 15px;
      background: #f2f2f2;
    }
  }
  .l-footer_nav {
    &-main {
      .l-footer_nav_list {
        &--item {
          &:last-child {
            .l-footer_nav_list--link {
              border-right: 0;
            }
          }
        }
        &--link {
          text-align: left;
          border: 0;
        }
      }
    }
    &-sub {
      .l-footer_nav_list {
        padding-top: 15px;
        &--item {
          &-pasona {
            flex-grow: 0;
            text-align: center;
            margin-left: -30px;
          }
        }
        &--link {
          text-align: left;
        }
      }
    }
  }
  .l-footer_nav_list {
    padding-left: 30px;
    &--link {
      text-align: left;
      border: 0;
      line-height: 1.8;
    }
  }
  .l-footer_copy {
    font-size: 12px;
    line-height: 1.5;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  //お気軽相談ダイヤル
  .l-footer_modal {
    &--content {
      padding: 16px 10px 20px;
      width: 300px;
    }
    &--heading {
      font-size: 18px;
      margin: 0 0 16px;
    }
    &--text {
      font-size: 14px;
    }
    &--consult {
      font-size: 16px;
      margin: 32px 0 8px;
    }
    &--tel {
      font-size: 24px;
    }
    &--hours {
      font-size: 14px;
    }
  }
}
