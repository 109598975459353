@charset "UTF-8";

@import "../mixin";

.l-header {
  position: fixed;
  width: 100%;
  height: 99px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  border-bottom: 1px solid #ccc;
  padding: 0 15px 0 24px;
  z-index: 10;
  @include sp {
    height: 70px;
    padding: 0 0 0 13px;
  }
  &--sp_only_fixed_link {
    display: none;
    @include sp {
      position: fixed;
      left: 0;
      bottom: 40px;
      border: 2px solid #fff;
      border-left: none;
      border-radius: 0 16px 16px 0;
      background-color: #333333;
      color: #fff;
      width: 82px;
      height: 78px;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.25;
      text-align: center;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2215.999%22%20viewBox%3D%220%200%2016%2015.999%22%3E%20%3Cpath%20id%3D%22Icon_awesome-pen%22%20data-name%3D%22Icon%20awesome-pen%22%20d%3D%22M9.085%2C2.914l4%2C4L4.4%2C15.6.832%2C16A.75.75%2C0%2C0%2C1%2C0%2C15.167L.4%2C11.6%2C9.085%2C2.914Zm6.475-.6L13.681.44a1.5%2C1.5%2C0%2C0%2C0-2.122%2C0L9.792%2C2.207l4%2C4L15.56%2C4.44a1.5%2C1.5%2C0%2C0%2C0%2C0-2.122Z%22%20transform%3D%22translate%280.001%20-0.001%29%22%20fill%3D%22%23f4b800%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat;
      background-position: center bottom 10px;
      padding-top: 9px;
      &.js-show {
        display: block;
      }
    }
  }
  &--menu {
    display: flex;
    align-items: center;
    @include sp {
      float: none;
      position: static;
    }
  }
  &--sns {
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 14px;
    color: #1777F2;
    display: flex;
    align-items: center;
    @include sp {
      display: none;
    }
    &:after {
      font-family: 'shigotojuku';
      content: '\f09a';
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &--sns_text {
    font-weight: $weight-bold;
    margin-right: 3px;
  }
  &--hamburger {
    display: none;
    @include sp {
      width: 70px;
      height: 70px;
      display: block;
      margin-right: 0;
      border-left: 1px solid #ccc;
      position: relative;
    }
    &-open {
      .l-header--hamburger_icon {
        transform: rotate(45deg);
        &:before {
          top: 0;
        }
        &:after {
          bottom: 0;
          transform: rotate(-90deg);
        }
      }
    }
  }
  &--hamburger_txt {
    @include sp {
      display: inline-block;
      transition: .2s;
      position: absolute;
      left: calc(100% - 4em);
      bottom: 5px;
      font-size: 14px;
      text-align: center;
    }
  }
  &--hamburger_icon {
    @include sp {
      width: 36px;
      height: 5px;
      border-radius: 3px;
      background: $color-main;
      position: absolute;
      transition: .2s;
      top: 27px;
      left: 15px;
      display: block;
    }
    &:before {
      @include sp {
        content: '';
        display: block;
        background: $color-main;
        border-radius: 3px;
        position: absolute;
        width: 36px;
        height: 5px;
        top: -12px;
      }
    }
    &:after {
      @include sp {
        content: '';
        display: block;
        background: $color-main;
        border-radius: 3px;
        position: absolute;
        width: 36px;
        height: 5px;
        bottom: -12px;
      }
    }
  }
  .m-btn {
    @include sp {
      display: none;
    }
    a {
      font-size: 16px;
      width: 240px;
      padding: 10px 20px;
      border-radius: 25px;
      &:after {
        margin-top: -12px;
        right: 10px;
      }
    }
  }
}
.l-header_logo {
  display: flex;
  align-items: center;
  &--tokyo {
    width: 140px;
    height: 40px;
    display: block;
    background: url('../images/tokyo.svg') no-repeat center center;
    background-size: cover;
    transition: .2s;
    @media screen and (max-width:1105px) {
      width: 100px;
      height: 29px;
      margin-top: 35px;
    }
    @include sp {
      display: none;
    }
  }
  &--4510jk {
    width: 220px;
    height: 70px;
    display: block;
    background: url('../images/logo.svg') no-repeat center center;
    margin-left: 20px;
    background-size: cover;
    transition: .2s;
    @media screen and (max-width:1105px) {
      width: 158px;
      height: 50px;
    }
    @include sp {
      width: 154px;
      margin-left: 0;
    }
  }
}
.l-header_sns {
  display: flex;
  align-items: center;
  margin-right: 24px;
  @include sp {
    margin-right: 20px;
  }
  &--link {
    width: 24px;
    height: 24px;
    display: block;
    transition: .2s;
    &:nth-child(n+2) {
      margin-left: 18px;
    }
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display: block;
      transition: .2s;
    }
    &:hover {
      &:before {
        opacity: 0.8;
      }
    }
    &-facebook {
      &:before {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224.147%22%20height%3D%2224%22%20viewBox%3D%220%200%2024.147%2024%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_9479%22%20data-name%3D%22%E3%83%91%E3%82%B9%209479%22%20d%3D%22M848.837%2C582.019a12.074%2C12.074%2C0%2C0%2C0-1.887%2C24v-8.437h-3.065v-3.49h3.065v-2.66c0-3.026%2C1.8-4.7%2C4.561-4.7a18.536%2C18.536%2C0%2C0%2C1%2C2.7.236v2.971h-1.522a1.745%2C1.745%2C0%2C0%2C0-1.968%2C1.885v2.265h3.349l-.536%2C3.49h-2.813v8.437a12.074%2C12.074%2C0%2C0%2C0-1.886-24Z%22%20transform%3D%22translate(-836.763%20-582.019)%22%20fill%3D%22%230076fb%22%2F%3E%3C%2Fsvg%3E');
      }
    }
    &-twitter {
      &:before {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_8906%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%208906%22%20transform%3D%22translate%28-635.625%20-12755%29%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3695%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203695%22%20width%3D%2224%22%20height%3D%2224%22%20transform%3D%22translate%28635.625%2012755%29%22%2F%3E%20%3Cpath%20id%3D%22logo%22%20d%3D%22M7.862%2C5.716%2C12.779%2C0H11.614L7.344%2C4.963%2C3.934%2C0H0L5.157%2C7.506%2C0%2C13.5H1.165L5.675%2C8.258l3.6%2C5.242H13.21L7.861%2C5.716Zm-1.6%2C1.855-.523-.747L1.585.877h1.79l3.355%2C4.8.523.747%2C4.361%2C6.239H9.824L6.265%2C7.572Z%22%20transform%3D%22translate%28640.875%2012760.25%29%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
      }
    }
  }
}
.l-header_nav {
  width: 100%;
  @include sp {
    display: none;
    top: 70px;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    background: #f2f2f2;
  }
}
.l-header_nav_list {
  width: 490px;
  margin: 0 auto;
  position: relative;
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width:1105px) {
    width: 460px;
  }
  @include sp {
    width: 100%;
    display: block;
    padding: 20px 30px;
  }
  &--item {
    width: calc(100% / 3);
    &-sp_only {
      display: none;
    }
    @include sp {
      width: 100%;
      margin-top: 10px;
      &-sp_only {
        display: block;
      }
    }
    &:first-child {
      @include sp {
        margin-top: 0;
      }
    }
  }
  &--link {
    width: 100%;
    font-size: 14px;
    font-weight: $weight-bold;
    line-height: 1.3;
    display: inline-block;
    padding: 5px 0;
    transition: .15s;
    &-sp_gray {
    }
    @include sp {
      padding-top: 10px;
      padding-bottom: 10px;
      background: $color-yl;
      text-align: center;
      border-radius: 20px;
      &-sp_gray {
        color: #fff;
        background-color: #5E5E5E;
      }
      &-sp_orange {
        background-color: #ff8a16;
        color: #fff;
      }
    }
    &:before {
      content: '●';
      color: $color-yl;
      @include sp {
        content: none;
      }
    }
    &:hover {
      color: $color-yl;
    }
  }
}

// IE
@media all and (-ms-high-contrast:none){
  .l-header_nav_list {
    &--link {
      font-weight: 400;
    }
  }
}

// for iphone se
@media screen and (max-width:320px) {
  .l-header {
    .m-btn {
      margin-right: 5px;
      a {
        padding-left: 10px;
        padding-right: 10px;
        &:after {
          content: none;
        }
      }
    }
  }
  .l-header_logo {
    &--logo {
      width: 90px;
      height: 29px;
    }
  }
}

